import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {IImageResponse} from '@ellen/user-be'
import {Observable} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'

export interface IFileUpload {
  /**
   * File's content type to send to BE
   */
  contentType: string
  /**
   * File's data that will be uploaded to AWS S3
   */
  data: Blob
  /**
   * File's ID that is received from server once it is saved in AWS S3.
   */
  id?: string
}

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public uploadImage(imageFile: IFileUpload): Observable<IImageResponse> {
    const headers = {
      'Content-Type': imageFile.contentType,
      'Cache-Control': 'max-age=4000'
    }

    // First we get an AWS put-URL from our BE and tmp-image ID
    const url = `${environment.apiUrl}/images/tmp`
    return this.httpClient
      .put<IImageResponse>(url, {contentType: imageFile.contentType})
      .pipe(
        switchMap((response: IImageResponse) => {
          const httpOptions = {
            headers: new HttpHeaders(headers)
          }
          // Then, we upload image data directly into AWS with that put-url
          return this.httpClient
            .put<void>(response.signedUrl, imageFile.data, httpOptions)
            .pipe(map(() => response))
        })
      )
  }

  public getViewUrl(id: string): Observable<string> {
    const url = `${environment.apiUrl}/images?path=${id}`
    return this.httpClient.get<string>(url)
  }

  public deleteImage(id: string): Observable<void> {
    const url = `${environment.apiUrl}/images?path=${id}`
    return this.httpClient.delete<void>(url)
  }
}
