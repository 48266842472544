import {Component, inject, input, model, output} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {IImageResponse} from '@ellen/user-be'
import {IFileUpload, ImagesService} from '../../../services/images.service'

@Component({
  selector: 'eln-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [
    MatIcon
  ],
  standalone: true
})
export class AvatarComponent {
  public image = model.required<string | undefined>()
  public size = input.required<number>()
  public disabled = input<boolean>(false)
  public selected = input<boolean>(false)
  public defaultIcon = input<string>('person')
  public canEdit = input<boolean>(false)

  public avatarChange = output<{ id: string, url: string | undefined }>()

  public uploadedImage: IFileUpload | null = null

  private imagesService: ImagesService = inject(ImagesService)

  public onFileChanged(event: any) {
    if (event.target.files.length === 0) {
      return
    }
    const file: File = event.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      if (file) {
        // Save image as string
        const dataUrl = reader.result as string
        this.image.set(dataUrl)

        // Create upload file
        const mimeType = file.type
        const blob = this.createBlob(dataUrl)

        this.uploadImageData({contentType: mimeType, data: blob})
      }
    }
    reader.readAsDataURL(file)
  }

  private createBlob(base64: string) {
    const base64String = base64.split(',')[1]
    const binaryString = atob(base64String)
    const byteArray = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i)
    }

    return new Blob([byteArray], {type: 'image/jpeg'})
  }

  private uploadImageData(file: IFileUpload) {
    const sendImageToApi = () => {
      this.imagesService.uploadImage(file).subscribe({
        next: (response: IImageResponse) => {
          this.avatarChange.emit({
            id: response.id,
            url: this.image()
          })
          this.uploadedImage = {...file, id: response.id}
        }
      })
    }

    // If there was an image before, first we delete that one, and then we
    // upload the new one
    if (this.uploadedImage?.id) {
      this.imagesService.deleteImage(this.uploadedImage.id)
        .subscribe(() => {
          this.uploadedImage = null
          sendImageToApi()
        })
    } else {
      sendImageToApi()
    }
  }
}
