<div class="add-user-holder">
  <eln-family-profile
    #profile
    [user]="user"
    (updatedUser)="updateUser($event)"
  ></eln-family-profile>

  <div class="add-user-actions-holder">
    <button class="eln-btn" mat-raised-button color="primary"
            [disabled]="!profile.isUserValid$()"
            (click)="saveUser()">Spara
    </button>

    <button class="eln-btn" mat-raised-button mat-dialog-close>
      Avbryt
    </button>
  </div>
</div>
