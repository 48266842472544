import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  standalone: true,
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value: string | undefined): string[] {
    if (!value) return ['Okänd', 'Användare']
    return value.split(' ')
  }

}
