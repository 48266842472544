import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes'
import {KeyValuePipe} from '@angular/common'
import {
  Component,
  ElementRef,
  input,
  OnInit,
  output,
  viewChild
} from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete'
import {
  MatChipGrid,
  MatChipInput,
  MatChipInputEvent
} from '@angular/material/chips'
import {MatIcon} from '@angular/material/icon'
import {
  MatFormField,
  MatHint,
  MatInput,
  MatSuffix
} from '@angular/material/input'
import {
  Allergies,
  FoodDiets,
  Intolerances,
  TFoodRestriction,
  TPreference,
  TPreferences,
  User
} from '@ellen/user-be'
import {Subject} from 'rxjs'
import {TranslateFilterPipe} from '../../../../pipes/translate-filter.pipe'
import {TranslatePipe} from '../../../../pipes/translate.pipe'
import {
  FamilyPreferencesChipComponent
} from '../preferences-chip/family-preferences-chip.component'

interface TFamilyPreference {
  key: TFoodRestriction
  title: string
  placeholder: string
  options?: TPreferences
  possibleOptions?: TPreferences
}

@Component({
  selector: 'eln-family-preferences',
  standalone: true,
  imports: [
    MatIcon,
    TranslatePipe,
    KeyValuePipe,
    FamilyPreferencesChipComponent,
    MatAutocompleteModule,
    MatInput,
    MatFormField,
    FormsModule,
    MatChipInput,
    MatHint,
    MatChipGrid,
    ReactiveFormsModule,
    TranslateFilterPipe,
    MatSuffix
  ],
  templateUrl: './family-preferences.component.html',
  styleUrl: './family-preferences.component.scss'
})
export class FamilyPreferencesComponent implements OnInit {

  /**
   * Local variable that is updated with every selected user. It changes thanks
   * to an observable from {@link UserService}
   */
  public user = input.required<User>()
  public resetStatus$ = input.required<Subject<boolean>>()
  public updatedUser = output<User>()

  public editingPreferenceKey: TFoodRestriction | null = null
  public fixedPreferenceInput =
    viewChild<ElementRef<HTMLInputElement>>('fixedPreferenceInput')
  public freeTextPreferenceInput =
    viewChild<ElementRef<HTMLInputElement>>('freeTextPreferenceInput')

  public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON]

  protected readonly preferences: TFamilyPreference[] = [
    {
      key: 'foodDiets',
      title: 'Välj dina matpreferenser',
      placeholder: 'sök efter matpreferenser...',
      options: [...FoodDiets],
      possibleOptions: [...FoodDiets]
    },
    {
      key: 'allergies',
      title: 'Välj dina allergier',
      placeholder: 'sök efter allergier...',
      options: [...Allergies],
      possibleOptions: [...Allergies]
    },
    {
      key: 'intolerances',
      title: 'Välj dina intoleranser',
      placeholder: 'sök efter intoleranser...',
      options: [...Intolerances],
      possibleOptions: [...Intolerances]
    },
    {
      key: 'dislikes',
      title: 'Välj vad du ogillar',
      placeholder: 'Skriv in vad du ogillar'
    }
  ]

  public ngOnInit() {
    // Whenever an event is received in "resetStatus$", it will hide any
    // open preference editing
    this.resetStatus$()
      .subscribe(() => this.editingPreferenceKey = null)
  }

  public onAddPreferenceClick(key: TFoodRestriction): void {
    this.editingPreferenceKey = key

    // We need to filter possible preferences at the beginning,
    // and after every change
    this.filterPossiblePreferences()

    // After opening the preference editing input, we focus on it.
    // We need to wait a bit because it's inside an @if, so it's not rendered
    // instantly. Hence, the "setTimeout" with 1ms.
    setTimeout(() => {
      key === 'dislikes' ?
        this.freeTextPreferenceInput()?.nativeElement.focus()
        : this.fixedPreferenceInput()?.nativeElement.focus()
    }, 1)
  }

  public addAutocompleteOption(key: TFoodRestriction, event: MatAutocompleteSelectedEvent) {
    // Add new value to user preferences
    const value = event.option.value as TPreference
    (this.user().settings[key] as TPreference[]).push(value)
    // Re-filter possible preferences
    this.filterPossiblePreferences()
    // Emit change
    this.updatedUser.emit(this.user())
    // Clear value after
    this.fixedPreferenceInput()!.nativeElement.value = ''
  }

  public addChipOption(key: TFoodRestriction, event: MatChipInputEvent): void {
    // Add new value to user preferences
    const value = event.value.trim() as TPreference

    if (value) {
      // Add new value to user preferences
      const array = this.user().settings[key] as TPreference[]
      array.push(value)
      // Re-filter possible preferences
      this.filterPossiblePreferences()
      // Emit change
      this.updatedUser.emit(this.user())
      // Clear value after
      this.freeTextPreferenceInput()!.nativeElement.value = ''
      event.chipInput.clear()
    }
  }

  public removePreference(currentTags: TPreference[], tag: TPreference): void {
    // Remove preference (tag) from user
    currentTags.splice(currentTags.indexOf(tag), 1)
    // Re-filter possible preferences
    this.filterPossiblePreferences()
    // Emit change
    this.updatedUser.emit(this.user())
  }

  private filterPossiblePreferences() {
    this.preferences.forEach(p => {
      p.possibleOptions = p.options?.filter(o =>
        !(this.user().settings[p.key] as string[]).includes(o))
    })
  }
}
