import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog, MatDialogClose, MatDialogRef} from '@angular/material/dialog'
import {DEFAULT_DIALOG_STYLE} from '../../../../application/constants'
import {User} from '@ellen/user-be'
import {FamilyProfileComponent} from '../profile/family-profile.component'

@Component({
  selector: 'eln-add-user-dialog',
  standalone: true,
  imports: [
    FamilyProfileComponent,
    MatButton,
    MatDialogClose
  ],
  templateUrl: './add-user-dialog.component.html',
  styleUrl: './add-user-dialog.component.scss'
})
export class AddUserDialogComponent {

  public dialogRef = inject(MatDialogRef<AddUserDialogComponent>)
  public user: User = new User()

  public static open(dialog: MatDialog): MatDialogRef<AddUserDialogComponent> {
    return dialog.open(AddUserDialogComponent, DEFAULT_DIALOG_STYLE)
  }

  public updateUser(user: User): void {
    this.user = user
  }

  public saveUser(): void {
    this.dialogRef.close(this.user)
  }
}
