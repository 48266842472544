<div class="avatar-holder"
     tabindex="-1"
     (keydown.enter)="canEdit() && fileUpload.click()"
     (click)="canEdit() && fileUpload.click()"
     [style.width.px]="size()"
     [style.height.px]="size()"
     [class.selected]="selected()"
     [class.disabled]="disabled()">
  @if (image()) {
    <img class="avatar-img" [src]="image()" alt="Avatar">
  } @else {
    <mat-icon class="avatar-icon"
              [style.width.px]="size() - 20"
              [style.height.px]="size() - 20"
              [style.font-size.px]="size() - 20"
    >{{ defaultIcon() }}
    </mat-icon>
  }

  <!-- Input field (non-visible) to handle file uploads -->
  <input #fileUpload
         (change)="onFileChanged($event)"
         style="display:none;"
         accept=".pdf, .png, .jpg"
         type="file"/>
</div>