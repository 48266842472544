import {DecimalPipe} from '@angular/common'
import {
  Component,
  input,
  OnChanges,
  OnInit,
  output,
  signal
} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatOption, MatSelect} from '@angular/material/select'
import {MatTooltip} from '@angular/material/tooltip'
import {Sexes, TSexes, User} from '@ellen/user-be'
import {dailyCaloriesIntake} from '@ellen/user-be/dist/gpt/calculations'
import {
  TAllergy,
  TFoodDiet,
  TIntolerance
} from '@ellen/user-be/dist/types/user-types'
import {debounceTime, filter} from 'rxjs'
import {
  PROFILE_MAX_ACTIVITY_LEVEL,
  PROFILE_MAX_AGE,
  PROFILE_MAX_HEIGHT,
  PROFILE_MAX_WEIGHT,
  PROFILE_MIN_ACTIVITY_LEVEL,
  PROFILE_MIN_AGE,
  PROFILE_MIN_HEIGHT,
  PROFILE_MIN_WEIGHT
} from '../../../../application/constants'
import {TranslatePipe} from '../../../../pipes/translate.pipe'
import {AvatarComponent} from '../../../common/avatar/avatar.component'

@Component({
  selector: 'eln-family-profile',
  standalone: true,
  imports: [
    AvatarComponent,
    ReactiveFormsModule,
    MatFormField,
    MatSuffix,
    MatInput,
    MatSelect,
    MatOption,
    MatLabel,
    TranslatePipe,
    DecimalPipe,
    MatIcon,
    MatTooltip
  ],
  templateUrl: './family-profile.component.html',
  styleUrl: './family-profile.component.scss'
})
export class FamilyProfileComponent implements OnInit, OnChanges {

  public user = input.required<User>()
  public updatedUser = output<User>()

  public isUserValid$ = signal<boolean>(false)

  protected readonly sexes = Sexes
  protected readonly dailyCaloriesIntake = dailyCaloriesIntake

  public form = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(3)]
    }),
    email: new FormControl<string>('', {
      validators: [Validators.email, Validators.minLength(3)]
    }),
    settings: new FormGroup({
      activityLevel: new FormControl<number>(2, {
        validators: [
          Validators.required,
          Validators.min(PROFILE_MIN_ACTIVITY_LEVEL),
          Validators.max(PROFILE_MAX_ACTIVITY_LEVEL)
        ]
      }),
      weight: new FormControl<number | null>(null, {
        validators: [
          Validators.required,
          Validators.min(PROFILE_MIN_WEIGHT),
          Validators.max(PROFILE_MAX_WEIGHT)
        ]
      }),
      height: new FormControl<number | null>(null, {
        validators: [
          Validators.required,
          Validators.min(PROFILE_MIN_HEIGHT),
          Validators.max(PROFILE_MAX_HEIGHT)
        ]
      }),
      age: new FormControl<number | null>(null, {
        validators: [
          Validators.required,
          Validators.min(PROFILE_MIN_AGE),
          Validators.max(PROFILE_MAX_AGE)
        ]
      }),
      sex: new FormControl<TSexes | null>(null, Validators.required),
      // Options not used but needed to do form.patch()
      foodDiets: new FormControl<TFoodDiet[]>([], {nonNullable: true}),
      allergies: new FormControl<TAllergy[]>([], {nonNullable: true}),
      intolerances: new FormControl<TIntolerance[]>([], {nonNullable: true}),
      dislikes: new FormControl<string[]>([], {nonNullable: true})
    }),
    // Options not used but needed to do form.patch()
    avatar: new FormControl<string | undefined>(undefined),
    avatarUrl: new FormControl<string | undefined>(undefined)
  })

  public ngOnInit(): void {
    // We listen to every change in form's data.
    // With every change, form's validity will be emitted using an output.
    this.form.valueChanges.subscribe(() =>
      this.isUserValid$.set(this.form.valid))

    // Also, with every change, but only every 200ms (debounceTime), we'll
    // send the updated user
    this.form.valueChanges.pipe(
      debounceTime(200)
    ).subscribe({
      next: () => {
        Object.assign(this.user(), this.form.getRawValue())
        this.updatedUser.emit(this.user())
      }
    })

    // Set automatic limitations in some fields
    this.setAutocorrectedLimits(this.form.controls.settings.controls.age,
      PROFILE_MIN_AGE, PROFILE_MAX_AGE)
    this.setAutocorrectedLimits(this.form.controls.settings.controls.weight,
      PROFILE_MIN_WEIGHT, PROFILE_MAX_WEIGHT)
    this.setAutocorrectedLimits(this.form.controls.settings.controls.height,
      PROFILE_MIN_HEIGHT, PROFILE_MAX_HEIGHT)
  }

  public ngOnChanges(): void {
    // Every user will always have a pre-selected activity level.
    // Default value will be "medium" activity, which is "2".
    this.user().settings.activityLevel = this.user().settings.activityLevel ?? 2

    // When a parameter changes, in this case just "user()", then we clear the
    // form and patch new user's values.
    this.form.reset(undefined, {emitEvent: false})
    this.form.patchValue(this.user(), {emitEvent: false})
    this.isUserValid$.set(this.form.valid)
  }

  public avatarImageChange(imageResponse: {
    id: string,
    url: string | undefined
  }) {
    this.form.controls.avatar.setValue(imageResponse.id)
    this.form.controls.avatarUrl.setValue(imageResponse.url)
  }

  public activitySelect(activity: number) {
    this.form.controls.settings.controls.activityLevel.setValue(activity)
  }

  private setAutocorrectedLimits(control: FormControl<number | null>, min: number, max: number) {
    control.valueChanges
      // We cannot user filter(Boolean) because 0 (zero) would be excluded
      .pipe(filter((val): val is number => val !== null))
      .subscribe((val) => {
        val = Math.max(min, val)
        val = Math.min(max, val)
        control.setValue(val, {emitEvent: false})
      })
  }
}
