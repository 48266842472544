import {Pipe, PipeTransform} from '@angular/core'
import {
  TAllergy,
  TFoodDiet,
  TIntolerance,
  TSexes
} from '@ellen/user-be'

const TranslateMap = new Map<TFoodDiet | TAllergy | TIntolerance | TSexes | string, string>([
  ['vegan', 'Vegan'],
  ['flexitarian', 'Flexeterian'],
  ['veganKeto', 'Vegan (Keto)'],
  ['vegetarian', 'Vegetarian'],
  ['vegetarianKeto', 'Vegetarian (Keto)'],
  ['pescetarian', 'Pescetarian'],
  ['milk', 'Mjölk'],
  ['nut', 'Nötter'],
  ['shellfish', 'Skaldjursallergi'],
  ['egg', 'Äggallergi'],
  ['soy', 'Sojaallergi'],
  ['wheat', 'Veteallergi'],
  ['sulfates', 'Sulfiter'],
  ['lupin', 'Lupin'],
  ['cereals', 'Spannmål'],
  ['crustacean', 'Kräftdjur'],
  ['fish', 'Fisk'],
  ['peanutSep', 'Jordnöt (sep)'],
  ['celery', 'Selleri'],
  ['mustard', 'Senap'],
  ['sesameSeed', 'Sesamfrö'],
  ['mollusc', 'Blötdjur'],
  ['lactoseIntolerance', 'Laktosintolerans'],
  ['glutenIntolerance', 'Glutenintolerans'],
  ['glutenSensitivity', 'Glutenkänslighet'],
  ['histamineIntolerance', 'Histaminintolerans'],
  ['female', 'Kvinna'],
  ['male', 'Man']
])

@Pipe({
  standalone: true,
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  transform(value: TFoodDiet | TAllergy | TIntolerance | TSexes | string): string {
    const res = TranslateMap.get(value)
    if (res) {
      return res
    }
    return value
  }
}