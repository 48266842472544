<div class="family-preferences-holder">
  @for (preferenceGroup of preferences; track $index) {
    <div class="family-preference-group-holder">
      <span>{{ preferenceGroup.title }}</span>

      <!-- Autocomplete-selector with multiple options -->
      @if (editingPreferenceKey === preferenceGroup.key) {
        @if (editingPreferenceKey !== 'dislikes' && preferenceGroup.possibleOptions) {
          <mat-form-field subscriptSizing="dynamic" appearance="outline"
                          class="family-preference-input">
            <input
                #fixedPreferenceInput
                type="text"
                placeholder="Välj i listan"
                matInput
                [placeholder]="preferenceGroup.placeholder"
                [matAutocomplete]="auto"
            >

            <mat-icon matIconSuffix class="family-preference-input-icon">
              search
            </mat-icon>

            <mat-autocomplete
                #auto="matAutocomplete"
                [hideSingleSelectionIndicator]="true"
                (optionSelected)="addAutocompleteOption(preferenceGroup.key, $event)"
            >
              @for (option of (preferenceGroup.possibleOptions
                      | translateFilter:fixedPreferenceInput.value
              ); track $index) {
                <mat-option [value]="option.key">{{ option.value }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        } @else {
          <mat-form-field subscriptSizing="dynamic" appearance="outline"
                          class="family-preference-input">
            <input
                #freeTextPreferenceInput
                (matChipInputTokenEnd)="addChipOption(preferenceGroup.key, $event)"
                [matChipInputAddOnBlur]="true"
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [placeholder]="preferenceGroup.placeholder"
                matInput
                type="text"/>
          </mat-form-field>
          <span class="family-preference-input-hint">
            Använd kommatecken eller enter för att lägga till
          </span>
          <mat-chip-grid #chipGrid></mat-chip-grid>
        }
      }

      <div class="family-preference-tags">
        @for (tag of user().settings[preferenceGroup.key]; track $index) {
          <eln-family-preferences-chip
            [value]="tag | translate"
            (remove)="removePreference(user().settings[preferenceGroup.key], tag)"
          ></eln-family-preferences-chip>
        }

        <mat-icon class="family-preference-add"
                  (keydown.enter)="onAddPreferenceClick(preferenceGroup.key)"
                  (click)="onAddPreferenceClick(preferenceGroup.key)"
        >add
        </mat-icon>
      </div>
    </div>
  }
</div>