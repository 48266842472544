import {HttpClient} from '@angular/common/http'
import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {
  DetailedFamily,
  FamilyMenuDay,
  GeneratedRecipe,
  IFamilyMenuDay,
  IFamilyRecipe,
  IGeneratedRecipe,
  IMenuDayConfig,
  IRecipe,
  IShoppingListItem,
  TDailyMeal,
  TDay,
  User
} from '@ellen/user-be'
import {
  BehaviorSubject,
  catchError,
  filter,
  first,
  from,
  map,
  NEVER,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
  tap
} from 'rxjs'
import {environment} from '../../environments/environment'
import {UserService} from './user.service'

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  /**
   * Signal-flag that marks if service is generating a menu/recipe
   */
  public isGeneratingMenu$ = signal<boolean>(false)
  /**
   * Signal-flag that marks if service is recovering a recipe
   */
  public isRecoveringRecipe$ = signal<boolean>(false)
  /**
   * Signal-flag that marks if service is saving a recipe
   */
  public isSavingRecipes$ = signal<boolean>(false)
  /**
   * Signal-flag that marks if service is deleting a recipe
   */
  public isDeletingRecipe$ = signal<boolean>(false)

  /**
   * For easier access, we keep a signal with current selected recipe
   * (or generated one) that will be used in RecipeComponent when ready
   */
  public loadedRecipe$: WritableSignal<IRecipe | null> = signal(null)
  /**
   * Family recipe associated to {@link loadedRecipe$}. The models are similar,
   * but one (Recipe) represents the real recipe on DB, and the other
   * (FamilyRecipe) keeps data that relates that recipe with a family.
   */
  public familyRecipe$: WritableSignal<IFamilyRecipe | null> = signal(null)

  /**
   * For easier access, we keep a signal with current generated recipes
   * that will be used in CreateMenuSummaryComponent.
   */
  public generatedRecipes$: Observable<GeneratedRecipe[] | null>
  private pGeneratedRecipes$: BehaviorSubject<GeneratedRecipe[] | null> =
    new BehaviorSubject<GeneratedRecipe[] | null>(null)

  private httpClient: HttpClient = inject(HttpClient)
  private userService: UserService = inject(UserService)

  private url = environment.webSocketUrl
  private socket: WebSocket = new WebSocket(this.url)
  private recipes$: Subject<GeneratedRecipe[]> = new Subject()

  constructor() {
    this.generatedRecipes$ = this.pGeneratedRecipes$.asObservable()
  }

  public static getIngredientsToBuyFromRecipe(recipe: IRecipe): IShoppingListItem[] {
    return (recipe.sections['ingredients'] ?? [])
      .map((ingredient: string): IShoppingListItem => {
        return {name: ingredient, checked: false}
      })
  }

  public getMenu(familyMembers: User[], dayConfigs: IMenuDayConfig[]): Observable<GeneratedRecipe[]> {
    return this.generateRecipes(familyMembers, dayConfigs)
      .pipe(
        first(),
        tap((generatedRecipes) => {
          // Set generated recipes
          this.pGeneratedRecipes$.next(generatedRecipes)
        })
      )
  }

  public retryGeneratedRecipe(familyMembers: User[], recipeToRetry: GeneratedRecipe): Observable<GeneratedRecipe> {
    // Create a configuration from GeneratedRecipe parameters
    const dayConfig: IMenuDayConfig = {
      day: recipeToRetry.day,
      [recipeToRetry.meal]: recipeToRetry.config
    }

    return this.generateRecipes(familyMembers, [dayConfig])
      .pipe(
        first(),
        filter(() => !!this.pGeneratedRecipes$.value),
        map((val) => val[0]),
        tap((retriedRecipe) => {
          // Get current generated recipes, and merge this retried one
          const generatedRecipes = this.pGeneratedRecipes$.value!
          const previousRecipeIndex = generatedRecipes.indexOf(recipeToRetry)
          generatedRecipes[previousRecipeIndex] = retriedRecipe

          // Set new generated recipes
          this.pGeneratedRecipes$.next(generatedRecipes)
        })
      )
  }

  public getRecipe(day: TDay, meal: TDailyMeal): Subject<IRecipe | null> {
    const recipe$ = new ReplaySubject<IRecipe | null>()

    // Start loading and create a method to stop loading later
    this.isRecoveringRecipe$.set(true)
    const stopLoading = (recipeReceived: IRecipe | null) => {
      this.loadedRecipe$.set(recipeReceived)
      recipe$.next(recipeReceived)
      recipe$.complete()
      this.isRecoveringRecipe$.set(false)
    }

    // We cannot get any recipe until we have a family loaded, so we listen to
    // changes in its observable.
    this.userService.family$
      .pipe(
        filter(Boolean),
        first()
      )
      .subscribe((family: DetailedFamily) => {
        // Now that we have the family, we check if they have a recipe for the
        // given day and meal. If not we return a null recipe
        const familyRecipe = family.getScheduleRecipe(day, meal)

        // Save found family recipe in signal to be used later.
        this.familyRecipe$.set(familyRecipe)

        if (familyRecipe === null) {
          stopLoading(null)
          return
        }

        // Family has the recipe on their schedule, so now we will retrieve
        // it from API. We need the recipe entity. Inside family's schedule
        // we only have IFamilyRecipe, which is more like a summary.
        this.getRecipeFromId(familyRecipe.recipeId)
          .subscribe({
            next: (recipe) => {
              // We save received recipe and we send it back. Also, stop loading
              stopLoading(recipe)
            },
            error: () => {
              // After an error we stop loaders and reset all
              stopLoading(null)
            }
          })
      })

    return recipe$
  }

  public saveGeneratedRecipes(generatedRecipes: GeneratedRecipe[]) {
    this.isSavingRecipes$.set(true)

    const familyId = this.userService.me$().familyId
    const recipesToSave = generatedRecipes
      .map(gr => gr.recipe)

    // First save recipes, then save family recipes
    let savedRecipes: IRecipe[] = []
    return this.saveRecipes(recipesToSave)
      .pipe(
        switchMap((recipes) => {
          savedRecipes = recipes

          return this.httpClient.put<IFamilyMenuDay[]>(
            `${environment.apiUrl}/family/${familyId}/recipe`,
            this.createFamilyMenuDaysFromGeneratedRecipes(
              savedRecipes, generatedRecipes)
          )
            .pipe(
              map((val: IFamilyMenuDay[]) =>
                val.map(fmd => new FamilyMenuDay(fmd))),
              // Update family's schedule after saving recipes
              tap((schedule) => {
                this.userService.updateFamilySchedule(schedule)
                this.isSavingRecipes$.set(false)
              }),
              // Get current family. We want it to update shopping list
              switchMap(() => this.userService.family$),
              first(),
              filter(Boolean),
              // Update family's shopping list with saved recipes' ingredients
              switchMap((family) => {
                const ingredientsToBuy = savedRecipes
                  .flatMap(RecipesService.getIngredientsToBuyFromRecipe)
                const shoppingList = family.shoppingList
                shoppingList.push(...ingredientsToBuy)
                return this.userService.saveFamilyShoppingList(shoppingList)
              }),
              tap(() => {
                this.isSavingRecipes$.set(false)
              }),
              catchError(() => {
                this.isSavingRecipes$.set(false)
                return NEVER
              })
            )
        }),
        catchError(() => {
          this.isSavingRecipes$.set(false)
          return NEVER
        })
      )
  }

  public removeFamilyRecipe(day: TDay, meal: TDailyMeal): Observable<FamilyMenuDay[]> {
    const familyId = this.userService.me$().familyId
    this.isDeletingRecipe$.set(true)

    const url = `${environment.apiUrl}/family/${familyId}/recipe?day=${day}&meal=${meal}`
    return this.httpClient.delete<IFamilyMenuDay[]>(url)
      .pipe(
        map((val: IFamilyMenuDay[]) =>
          (val ?? []).map(fmd => new FamilyMenuDay(fmd))),
        tap((schedule: FamilyMenuDay[]) => {
          // Update family's schedule after saving recipes
          this.userService.updateFamilySchedule(schedule)
          this.isDeletingRecipe$.set(false)
        }),
        catchError(() => {
          this.isDeletingRecipe$.set(false)
          return NEVER
        })
      )
  }

  private generateRecipes(familyMembers: User[], dayConfigs: IMenuDayConfig[]): Observable<GeneratedRecipe[]> {
    this.isGeneratingMenu$.set(true)

    return from(this.initializeWebSocket())
      .pipe(
        first(),
        switchMap(() => {
          return this.askForMenu({
            family: familyMembers,
            days: dayConfigs
          })
        }),
        map((val: IGeneratedRecipe[]) => val.map(gr => new GeneratedRecipe(gr))),
        tap(() => {
          this.isGeneratingMenu$.set(false)
          this.socket.close()
        }),
        catchError(() => {
          this.isGeneratingMenu$.set(false)
          this.socket.close()
          return NEVER
        })
      )
  }

  private initializeWebSocket(): Promise<void> {
    this.socket = new WebSocket(this.url)
    return new Promise((resolve, reject) => {
      this.socket.onopen = () => {
        resolve()
      }

      this.socket.onmessage = (event: { data: string }) => {
        try {
          const recipes: GeneratedRecipe[] = JSON.parse(event.data)
          this.recipes$.next(recipes.map(gr => new GeneratedRecipe(gr)))
          this.recipes$.complete()
        } catch (e) {
          this.recipes$.error(e)
        }
      }

      this.socket.onerror = (error: any) => {
        reject(error)
      }
    })
  }

  private askForMenu(prompt: {
    family: User[],
    days: IMenuDayConfig[]
  }) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({...prompt, action: 'MENU'}))
      this.recipes$ = new Subject<GeneratedRecipe[]>()
    } else {
      console.error('WebSocket connection is not open.')
    }
    return this.recipes$
  }

  private saveRecipes(recipes: IRecipe[]): Observable<IRecipe[]> {
    const url = `${environment.apiUrl}/recipe/multi`
    return this.httpClient.put<IRecipe[]>(url, recipes)
  }

  private getRecipeFromId(recipeId: string): Observable<IRecipe> {
    const url = `${environment.apiUrl}/recipe/${recipeId}`
    return this.httpClient.get<IRecipe>(url)
  }

  private createFamilyMenuDaysFromGeneratedRecipes(
    savedRecipes: IRecipe[],
    generatedRecipes: GeneratedRecipe[]
  ): FamilyMenuDay[] {
    // NOTE!! We assume that we are here with recipes with no errors on them

    const familyMenuDays: FamilyMenuDay[] = []
    generatedRecipes
      // We filter out all those recipes that have errors
      .filter((generatedRecipe: GeneratedRecipe) => !generatedRecipe.error)
      // Then, we start generating family days
      .forEach((generatedRecipe: GeneratedRecipe) => {
        // Get existent family menu day
        let familyMenuDay = familyMenuDays
          .find(d => d.day === generatedRecipe.day)
        // If no present yet, generate a new one and add it to array
        if (!familyMenuDay) {
          familyMenuDay = new FamilyMenuDay({day: generatedRecipe.day})
          familyMenuDays.push(familyMenuDay)
        }

        const savedRecipe = savedRecipes.find(r =>
          r.id === generatedRecipe.recipe.id)!

        // Add/Override meal in family menu day
        familyMenuDay[generatedRecipe.meal] = {
          id: 'temp_id_' + savedRecipe.id,
          recipeId: savedRecipe.id,
          title: savedRecipe.title,
          meal: generatedRecipe.meal,
          imageId: savedRecipe.imageId,
          ...generatedRecipe.config
        }
      })

    return familyMenuDays
  }
}
