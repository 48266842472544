<mat-card class="family-holder">
  <div class="header-logo">
    <img
      class="header-logo"
      alt="Joshu logo"
      src="assets/logo_joshu_black.png">
  </div>

  <div class="users-holder">
    <div class="family-members-holder">
      @for (member of userService.familyMembers$(); track $index) {
        <div class="family-member-holder"
             [class.selected]="member.id === (userService.selectedUser$ | async)?.id">
          @if (userService.isUpdatingUser$() === member.id) {
            <mat-spinner></mat-spinner>
          }

          <eln-avatar
            (keydown.enter)="switchUser(member.id)"
            (click)="switchUser(member.id)"
            [image]="member.avatarUrl"
            [size]="member.id === (userService.selectedUser$ | async)?.id ? 70 : 50"
            [disabled]="member.id !== (userService.selectedUser$ | async)?.id"
            [selected]="member.id === (userService.selectedUser$ | async)?.id"
          ></eln-avatar>

          <div class="family-member-name">
            @for (namePart of member.name | split; track $index) {
              <!-- We only show two lines, no more -->
              @if ($index <= 1) {
                <span>{{ namePart }}</span>
              }
            }
          </div>
        </div>
      }
    </div>

    <!-- Add family member holder - We use same holder as other bubbles -->
    <div class="family-member-holder">
      <button (click)="addUser()" class="add-family-member" mat-raised-button
              color="primary">
        <mat-icon class="add-icon">add</mat-icon>
      </button>

      <!-- Same spacing as other family member's bubble -->
      <div class="family-member-name">
        <span>Lägg till...</span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <eln-family-settings></eln-family-settings>
</mat-card>
