import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatDivider} from '@angular/material/divider'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {MatTab, MatTabGroup} from '@angular/material/tabs'
import {MatTooltip} from '@angular/material/tooltip'
import {User} from '@ellen/user-be'
import {filter, Subject, Subscription, switchMap} from 'rxjs'
import {CREATING_USER_ID} from '../../../../application/constants'
import {ConfigService} from '../../../../services/config.service'
import {UserService} from '../../../../services/user.service'
import {
  ConfirmationDialogComponent
} from '../../../common/confirmation-dialog/confirmation-dialog.component'
import {
  CloseAccountDialogComponent
} from '../close-account-dialog/close-account-dialog.component'
import {
  FamilyPreferencesComponent
} from '../preferences/family-preferences.component'
import {FamilyProfileComponent} from '../profile/family-profile.component'

@Component({
  selector: 'eln-family-settings',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    FamilyPreferencesComponent,
    FamilyProfileComponent,
    MatDivider,
    MatTooltip,
    MatButton,
    MatProgressSpinner
  ],
  templateUrl: './family-settings.component.html',
  styleUrl: './family-settings.component.scss'
})
export class FamilySettingsComponent implements OnInit, OnDestroy {

  public user!: User
  public isUserModified: boolean = false
  public resetEditingOptions$: Subject<boolean> = new Subject<boolean>()

  public dialog: MatDialog = inject(MatDialog)
  public userService: UserService = inject(UserService)
  public configService: ConfigService = inject(ConfigService)

  protected readonly CREATING_USER_ID = CREATING_USER_ID

  private currentUserAsString: string = ''
  private sub$ = new Subscription()

  public ngOnInit() {
    this.sub$ = this.userService.selectedUser$
      .pipe(filter(Boolean))
      .subscribe((user: User) => {
        // We save selected user, in fact, a copy of it. So it doesn't get
        // modified in other parts of the UI when editing. It should only
        // get modified once it is saved.
        this.user = new User(JSON.parse(JSON.stringify(user)))
        // We also save user as string, to be able to track changes on it.
        this.currentUserAsString = JSON.stringify(this.user)
        // Reset changes flag every time we switch user
        this.isUserModified = false
        // Send event to reset any editing option. Children should use this.
        this.resetEditingOptions$.next(true)
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
    this.resetEditingOptions$.complete()
  }

  public updateUser(user: User): void {
    // Update user with every modification
    this.user = user
    // Also, calculate if there has been any change
    this.isUserModified = JSON.stringify(this.user) !== this.currentUserAsString
  }

  public save(): void {
    this.resetEditingOptions$.next(true)

    this.userService.saveUser(this.user)
      .subscribe(() => {
        // Reset changes flag after updating user
        this.isUserModified = false
        // And re-calculate current user as string
        this.currentUserAsString = JSON.stringify(this.user)
      })
  }

  public removeFromFamily(): void {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Är du säker på att du vill ta bort personen från familjen?',
      okButton: 'Ta bort'
    }).afterClosed()
      .pipe(
        // We only remove users if "OK" was clicked in dialog
        filter(Boolean),
        // Remove user from family
        switchMap(() => this.userService.removeFromFamily(this.user))
      )
      // Reset changes flag after updating user
      .subscribe(() => this.isUserModified = false)
  }

  public onCloseAccountClick(): void {
    CloseAccountDialogComponent.open(this.dialog)
  }
}
